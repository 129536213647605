<template>
  <div>
    <img id="Master" :src="require('@/assets/Rothinger case.png')" alt="can't load image">
    <H1>The Rothinger Case</H1>
    <p class="Description">This was a two person Unity project, made to put theory and knowlegde gained from previous
      assignments to use.
    <p class="Description"> My tasks during this project were: Creating most of the used 3D models, design the game map,
      lighting and
      creating an item inspection system to be able to pick up and rotate an item in the game world. The youtube video
      below is the final result of the project</p>
    <br/>
    <H2>A recording of the project in action</H2>
    <div class="video-box">
      <iframe width="280" height="107.5" src="https://www.youtube.com/embed/8OL7sk2W460" frameborder="0"
              allow="accelerometer; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="CodeSnippets">
      <H2>Code</H2>
      <p class="Description"> Of the work I've done during this project I'm most proud about the item inspection system.
        As I was inexperienced with unity it was challenging to get everything to work properly. Below is the code
        required for the system and I will explain how it works.</p>

      <H3>Inspectable item</H3>
      <img :src="require('@/assets/InspectableItem.png')" alt="can't load image">
      <p class="Description">The code shown above is required to create an inspectable item. The code below will search
        for a game object with the script above added to it</p>
      <p class="Description">The function of this code besides making it an inspectable item, is also making it easier
        to notice in the game, by creating a halo that activates if the player hovers the mouse over it</p>
      <H3>Inspector</H3>
      <img :src="require('@/assets/InspectorRayCast1.png')" alt="can't load image">
      <img :src="require('@/assets/InspectorRayCast2.png')" alt="can't load image">
      <p class="Description">The function of this code is to activate the halo and if the player clicks, bring the
        object to a position close to the camera so it can be inspected. Once the player is done inspecting, they can
        return the object to it's original position with right click.</p>
    </div>

    <H2> 3D models</H2>
    <p class="Description">During this project I've been busy with the models required for the game. Below I added
      images of the models I've made during this project</p>
    <div class="Models">
      <img :src="require('@/assets/RothingerCaseMap.png')" alt="can't load image">
      <img :src="require('@/assets/HospitalRoom.png')" alt="can't load image">
      <img :src="require('@/assets/HospitalBed.png')" alt="can't load image">
      <img :src="require('@/assets/WalkieTalkie.png')" alt="can't load image">
      <img :src="require('@/assets/Key,png.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "TheRothingerCase"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>