<template>
  <nav>
    <ul>
      <li>
        <router-link to="/" exact>Home</router-link>
      </li>
      <li>
        <router-link to="/GameProjects" exact>Game Projects</router-link>
      </li>
      <li>
        <router-link to="/OtherProjects" exact>Other Projects</router-link>
      </li>
      <li>
        <router-link to="/AboutMe" exact>About Me</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PhoneNavigation"
}
</script>

<style scoped>

ul {
  align-items: center;
  list-style-type: none;
  width: 200px;
  text-align: center;
  padding-left: 40px;
}

li {
  font-size: 1.5rem;
  display: inline-block;
  padding: 2px 10px;
  cursor: pointer;
  margin-bottom: 10px;

}

a {
  text-align: center;
  color: #0B0C10;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 10px;
}

.router-link-active {
  background: #0B0C10;
  color: #45A29E;
}

nav {
  border-radius: 20px;
  top: 30px;
  padding: 20px 10px 0 0;
  background: #C5C6C7;
  width: 270px;
  height: 270px;
}
</style>