<template>
  <div id="Content">
    <H1 id="Title">About me</H1>
    <div class="container">
      <img :src="require('@/assets/20201116_124107.jpg')" alt="can't load image">
    </div>
    <p>Below you can find my CV, followed by the programming language I'm experienced with as well as the IDE's and game
      engines I'm familiar working with.</p>

    <H2>CV</H2>
    <p>Click the link below to open a pdf version of my Resume on a new tab.</p>
    <a id="Resume" v-on:click="openCV">Resume</a>

    <H2>I have experience with the following programming languages:</H2>
    <ul>
      <li>Html/Css</li>
      <li>Javascript</li>
      <li>C#</li>
      <li>Java+websockets</li>
      <li>Asp.net</li>
      <li>MSSQL/MySQL</li>
    </ul>

    <p>Of the programming languages mentioned above I have the most experience working with C# and MSSQL.
      Html and CSS I worked with the least and am still learning about.
      This portfolio is made using a combination of HTML, CSS and Vue js as javascript framework.
    </p>

    <H2>I've worked with the following IDE's and game engines:</H2>
    <ul>
      <li>Visual studio code</li>
      <li>Visual studio</li>
      <li>IntelliJ</li>
      <li>Unity Engine</li>
      <li>Unreal Engine</li>
    </ul>
    <p>Of the above mentioned IDE's and game engine's I have quite a bit of experience with all except Unreal.
      This as I'm still quite new to the platform and haven't been able to use the game engine a lot for self study
      projects yet.</p>

    <H1>Contact info</H1>
    <ul>
      <li>Email me at: lesliecrielaard@hotmail.com</li>
      <li>Find me on LinkedIn: <a href="https://nl.linkedin.com/in/leslie-crielaard-961a3b1b9" target="_blank">To
        linkedin</a></li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "About me",
  methods: {
    openCV() {
      this.$router.push("/CV")
    }
  }
}
</script>

<style scoped>
#Content {
  align-content: center;
}

#Resume {
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
  color: #66FCF1;
}

a {
  color: #66FCF1;
}

ul, p {
  font-family: Arial, sans-serif;
  max-width: 38em;
  margin: 2em auto 20px;
  color: whitesmoke;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}

li {
  text-align: left;
  color: whitesmoke;
}

H1 {
  font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  color: #66FCF1;
}

H2 {
  font-size: calc((.4em + 1.5vmin) + (.4em + 1.5vmax));
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  color: #66FCF1;
}

.container {
  width: 100%;
  height: auto;
  padding-top: 0;
  margin: 0 10px 10px 10px;
  text-align: center;
  position: center;
}

.container img {
  pointer-events: none;
  height: auto;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  border-radius: 1rem;
  display: block;
}
</style>