<template>
  <div>
    <img id="Master" :src="require('@/assets/CityBuildIdleMainMenu.png')" alt="can't load image">
    <H1>CityBuild Idle</H1>
    <p class="Description">This was originally a duo game project but my teammate left the study after the idea was
      formed</p>
    <p class="Description">This game is a project I worked on by myself for 9 weeks. The result of this is a simple base
      for a citybuilding game. This game works both on pc and on mobile.</p>

    <p class="Description">The first, low fidelity prototype I made for the game contained most of the rough
      functionality of the game in a greybox form. The idea of the greybox is comparable to a rough sketch of how the
      final project might look.
      Below I have added a video to show the first version of the game, 4 weeks into the project</p>
    <H2>low fidelity demo</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/RSxJUHNgueQ" frameborder="0"
              allow="accelerometer; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <p class="Description">The functionality of the game is the same for both pc and mobile, but has 1 change to the
      movement of the camera as tapping with a finger is less precise than a mouse click.</p>
    <p class="Description">Below two video's to show the working end result</p>

    <H2>A high fidelity recording of the project in action on pc</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/Z1CKoWddF5o" frameborder="0"
              allow="accelerometer;gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <H2>A high fidelity recording of the project in action on mobile</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/oWaEBYkq0tQ" frameborder="0"
              allow="accelerometer; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <H2>Tasks during the project</H2>
    <p class="Description"> During this project I've worked on a lot of diverse tasks. Below a short summary of the
      tasks I have done to make the project shown above</p>
    <ul>
      <Li>Creating 3D models in blender</Li>
      <Li>Creating simple textures in blender</Li>
      <Li>Creating the main menu</Li>
      <li>Writing resource logic</li>
      <li>Writing a happiness calculation</li>
      <li>Writing build logic</li>
      <li>Creating build and resource UI</li>
      <li>Writing build logic</li>
    </ul>
    <div class="UI_elements">
      <H3>Main menu UI</H3>
      <img :src="require('@/assets/CityBuildIdleMainMenu.png')" alt="can't load image">
      <H3>Resource UI</H3>
      <img :src="require('@/assets/GameScreenUI.png')" alt="can't load image">
      <H3>Build UI</H3>
      <img :src="require('@/assets/BuildUI.png')" alt="can't load image">
    </div>
    <div class="CodeSnippets">
      <H2>Code snippets</H2>
      <H3>Camera control logic</H3>
      <img :src="require('@/assets/CameraControl.png')" alt="can't load image">
      <p class="Description">The code snippet shown above controls camera rotation. The Input.touchCount checks if the
        input is a touch on a touchscreen. If a touch is detected, it uses the code within the if statement to rotate
        the screen with finger drag distance</p>
      <p class="Description">The second if statement with Input.GetMouseButton checks if the left mouse button is
        pressed. If this is the case, the code within the if statement is used to rotate with mouse control</p>

      <H3>Raycasts to get position of where a building must be placed</H3>
      <img :src="require('@/assets/RaycastForPosition.png')" alt="can't load image">
      <p class="Description">The code snippet above fires the raycasts when a player either touches the screen or clicks
        with the mouse on the position where the action took place on the screen.</p>
      <img :src="require('@/assets/RayCastPositionReceiver.png')" alt="can't load image">
      <p class="Description">The code snippet above receives the raycast data and checks for a specific script before
        allowing the build UI to be enabled.</p>

      <H3>Game update logic</H3>
      <img :src="require('@/assets/UpdateLogic.png')" alt="can't load image">
      <p class="Description">The code snippet makes sure the different values are updated automatically as long as the
        game runs. This update takes place every time a certain amount of second has passed</p>

      <H3>Happiness calculation logic</H3>
      <img :src="require('@/assets/happinessLogic.png')" alt="can't load image">
      <p class="Description">The code snippet above is used to calculate the current happiness of the present
        population. The values the calculation is based on are:</p>
      <ul>
        <li>Food</li>
        <li>Water</li>
        <li>Electricity</li>
        <li>Jobs</li>
      </ul>
      <p class="Description">If one of these values does not satisfy the demand of the population, the happiness is
        affected. and reduced by a certain percentage</p>

      <h2>Factory logic</h2>
      <img :src="require('@/assets/FactoryWorkerLogic.png')" alt="can't load image">
      <p class="Description">The code snippet shown above is used to check the amount of workers in a factory and add or
        remove a certain amount of workers if required.
        This logic makes sure that only the amount of workers that fits get added and the maximum doesn't get exceeded
        and the amount of workers won't go below</p>
    </div>

    <H2> 3D models</H2>
    <p class="Description">As I had to keep the project rather simple due to the fact I had to work alone, I didn't have
      time to create a lot of assets.
      In total this project has 6 different 3D models, of which 1 is a simple cube with a texture.
      The image below shows all the buildings I have added into the game in blender</p>
    <div class="Models">
      <img :src="require('@/assets/CityBuild buildings.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "CityBuildIdle"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">

</style>