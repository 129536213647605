import Vue from 'vue';
import Router from 'vue-router';
import HomePage from "@/components/HomePage";
import About_me from "@/components/About me"
import GamesMade from "@/components/GamesMade"
import Projects from "@/components/Projects completed"
import Case from "@/components/Games/TheRothingerCase"
import FireAndQuestions from "@/components/Games/FireAndQuestions";
import CargoRunner from "@/components/Games/CargoRunner";
import Recontain from "@/components/Other project/Project Recontain"
import SpaceInvadersWinForms from "@/components/Other project/SpaceInvadersWinForms";
import BulletHell from "@/components/Other project/1943WinForms";
import PDFViewer from "@/components/PDFVieuwer";
import CityBuildIdle from "@/components/Games/CityBuildIdle";
import DutchGameStudio from "./components/Stage/DutchGameStudio";
import SpaceoticGamejam from "./components/Games/SpaceoticGamejam";

Vue.use(Router);

export const router = new Router({
    mode: "history",
    routes: [
        {path: '/', component: HomePage},
        {path: '/AboutMe', component: About_me},
        {path: '/GameProjects', component: GamesMade},
        {path: '/OtherProjects', component: Projects},
        {path: '/RothingerCase', component: Case},
        {path: '/FireAndQuestions', component: FireAndQuestions},
        {path: '/CargoRunner', component: CargoRunner},
        {path: '/Recontain', component: Recontain},
        {path: '/WinForms', component: BulletHell},
        {path: '/Invaders', component: SpaceInvadersWinForms},
        {path: '/CV', component: PDFViewer},
        {path: '/CityBuild', component: CityBuildIdle},
        {path: '/DGS',component:DutchGameStudio},
        {path: '/SpaceoticGameJam',component:SpaceoticGamejam}
    ]
})