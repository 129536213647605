<template>
  <div>
    <img id="Master" :src="require('@/assets/Space-otic_logo.png')" alt="can't load image">
    <H1>Space-otic escape</H1>
    <p class="Description">This was a seven person Unity project for a 1 week gamejam during my gamedesign minor.</p>
    <p class="Description">During the first week of my minor, the teachers had set up a gamejam to get everyone back
      into programming games after the summer holiday.
      The theme of the GameJam was: "Let there be chaos!" Inspired by a gamejam previously hosted by the Brackeys
      community.
      The final game had to be published on Itch.io. At the end of this page I have added a link to the published game
      to be able to experience it yourself.
    </p>
    <br/>
    <H2>Concepting stage</H2>
    <div>
      <p class="Description">On day 1 of the GameJam I was tasked with forming a group with fellow students to work
        with.</p>
      <p class="Description">After forming the group, we started discussing possible ideas, starting with what each team
        member's idea about the meaning of the word 'chaos'. For this we then gathered games of our interest we could
        use for inspiration for our own game.
        The two games we eventually chose for our first concept were: 60 seconds and Among Us. I will explain in short
        what the games are and what ideas the games gave us.</p>
      <br/>
      <div class="Models">
        <H3>60 seconds</H3>
        <img :src="require('@/assets/60Seconds.jpg')" alt="can't load image">
        <img :src="require('@/assets/60SecondsGathering.png')" alt="can't load image">
        <p class="Description">60 seconds is a 2D story/event based survival game with a twist. The game allows the
          player to gather supplies to use during the actual survival part of the game. The gathering takes place in a
          3D-environment, separate from the survival itself.
          The game gives the player 60 seconds to gather items and people and deliver them to the marked area in the
          gameplay screenshot.</p>
        <p class="Description">If the
          player itself doesn't manage to reach the marked area before the timer runs out the game will end early</p>
        <br>
        <p class="Description">The idea of gathering and dropping off items caught our interest during discussions about
          and further investigation of the game.</p>
      </div>
      <br/>
      <div class="Models">
        <H3>Among Us</H3>
        <img :src="require('@/assets/among us.jpg')" alt="can't load image">
        <img :src="require('@/assets/among_usSpace.jpg')" alt="can't load image">
        <p class="Description">Among us is a 2D online multiplayer deduction game and splits the players into two
          'teams' of sorts. The game has players either completing tasks or sabotaging and killing other players. </p>
        <p class="Description">The game has three different winning conditions, these being: The players complete all
          tasks, the players vote off
          all the killers or the killers have killed all other players.</p>
        <p class="Description">
          Among Us also has different kinds of levels in which can be played, but the space themed characters, lobbies
          and levels are what gave us inspiration for our game's theme.</p>
      </div>
    </div>
    <br>
    <p class="Description">The final concept we made up from the ideas received from our inspiration games was: a
      chaotic, space-themed collecting game. With this idea in mind we presented our first thoughts to the teachers
      and our fellow students, before moving on to the design stage of our game.</p>
    <br/>
    <H2>Design stage</H2>
    <p class="Description">After discussing about our chosen inspiration games and the elements of the games we want to
      use in our game, we started with first ideas and create a simple prototype of our idea</p>
    <p class="Description"></p>
    <p class="Description"> My tasks during this project were:</p>
    <ul>
      <Li>Assisting with the creation of a minecraft demo of our game</Li>
      <li>Creating item pickup logic</li>
      <li>Creating menu UI</li>
    </ul>
    <p class="Description"> For each of the tasks I will share some results and explain what I've done.</p>
    <br/>
    <div class="Models">
      <H3>Minecraft levels</H3>
      <p class="Description">Below I've added some minecraft levels made to prototype different sizes of maps for our game and to experience how they would play out.</p>
        <img :src="require('@/assets/SmallMinecraftLevel.png')" alt="can't load image">
      <img :src="require('@/assets/ShipLikeLevel.png')" alt="can't load image">
      <p class="Description">For these two levels I assisted with creating the level shape and rooms, together with adding details, like, for example smoke. This smoke being generated from campfires placed underneath the level.</p>
    </div>
    <div class = "Models">
      <H3>Minecraft levels to unity game world</H3>
      <p>From the inspiration and feedback we received when showing our demo we created a level in unity that's based on the received feedback. Below I added a few images showing the level we made.</p>
      <p>The assets used to create the level are from the unity asset store and not self made.</p>
      <p>Link to the Unity asset store page of the assets:<a href="https://assetstore.unity.com/packages/3d/environments/sci-fi/sci-fi-styled-modular-pack-82913" target="_blank">
        here</a></p>
      <img :src="require('@/assets/SpaceShipExterior.png')" alt="can't load image">
      <img :src="require('@/assets/SpaceShipCorridor.png')" alt="can't load image">
      <img :src="require('@/assets/StarterRoom.png')" alt="can't load image">
      <img :src="require('@/assets/MainRoom.png')" alt="can't load image">
      <img :src="require('@/assets/Cockpit.png')" alt="can't load image">
    </div>
    <br/>
    <div class="Models">
      <H3>Item pickup logic</H3>
      <p class="Description">For the item pickup I don't have an image or video of the code working, but I do have a code sample to show.</p>
    </div>
      <div class="Particles">
      <img :src="require('@/assets/ItemPickup.png')" alt="can't load image">
      </div>
    <div class="Models">
      <p class="Description">The Code above is required for picking up and dropping off items in the game. To win the game the player needs to collect all items scattered around the level and bring them to an escape pod.</p>
      <p class="Description">To pick up an item, the Pickup method is used. This method works with a raycast to check if the item is on  specific layermask before attempting to grab the object. If the object has the layermask given in the Start method, it will be added to the inventory.</p>
      <p class="Description">The hover over method that is called before pickup, creates an outline around an item on the in start mentioned layermask to indicate that the player can interact with an object.</p>
      <p class="Description">The last two methods in the code, the OnTriggerEnter and the OntriggerExit are to complete the level when all items are collected or the game ends, and to empty the inventory of the player into it's escape pod.</p>
     <br>
      <p>Escape pod</p>
      <img :src="require('@/assets/DropOffPoint.png')" alt="can't load image">
      <p class="Description"> The escape pod and scene are made by a teammate.</p>
    </div>
    <div class = "Models">
      <H3>Creating menu UI</H3>
      <p class="Description">To play our game and read about the controls, we need UI that enables the player to perform these basic actions.</p>
      <p class="Description">The assets used to create the UI are from the unity asset store and not self made. I did however customise the visuals of the assets to match with the game myself</p>
      <p class="Description">The assets used to create the level are from the unity asset store and not self made.</p>
      <p class="Description">Link to the Unity asset store page of the assets:<a href="https://assetstore.unity.com/packages/2d/gui/sci-fi-gui-skin-15606" target="_blank">
        here</a></p>
      <img :src="require('@/assets/MainMenu.png')" alt="can't load image">
      <img :src="require('@/assets/ControlsUI.png')" alt="can't load image">
    </div>
<br/>
<br/>
    <H2>Itch.io Game link</H2>
    <p class="Description">Below is the link to the published version of the GameJam game.
      There are some warnings required for the game, as we didn't have time to polish it enough.</p>
    <p class="Description">1: Sound is pretty loud in the game. We didn't have time to optimize the audio before the end
      of the week.</p>
    <p class="Description">2: Please wait in the spawn room after starting the game. We weren't able to add logic to lock
      the door until the timer starts.</p>
    <p class="Description">3: The pickup logic for items is not working 100% correctly. This is due to issues caused by
      webGL. In unity engine builds of the game it works as expected</p>
    <p class="Description">4: Directions. The location of the 'Escape Pod' is at the end of the hallway on the right when
      you exit the starting room. We wanted to add furniture and signs to tell what room is where, but we didn't have
      time to add it to the game sadly, leaving directions pretty vague.</p>
    <br/>
    <p class="Description">Below I have added the link to play the game on itch.io</p>
    <p class="Description"><a href="https://fontys-gdt.itch.io/spaceoticescape" target="_blank">Click here to play the
      game</a></p>
  </div>
</template>

<script>
export default {
  name: "Space-oticGamejam"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">

</style>