<template>
  <div>
    <img id="Top" :src="require('@/assets/1943WinForms.png')" alt="can't load image">
    <H1>WinForms</H1>
    <p class="Description">This project was my second ever C# windows forms and a clone of the 1943 bullet-hell video
      game.
      This project is one of my biggest accomplishments as beginning programmer.
      It has most functionality of the original game and this game can also be controled with an arduino using distance
      sensors.</p>
    <br/>
    <H2>A recording of the project in action</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/u2eStKVBIOI" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
    <H2>Code snippets</H2>
    <div class="CodeSnippets">
      <img :src="require('@/assets/Bullet.png')" alt="can't load image">
      <p class="Description">This code snippet above is for the spawning of bullets. A bullet in this game is a purple
        dot that shoots forward to the top of the screen at a specific speed.</p>
      <img :src="require('@/assets/InheritableClass.png')" alt="can't load image">
      <p class="Description">This code snippet above is an inheritable class that is used for the enemies in the game.
        This inheritable class is used to have less duplicate code in the project and makes the project a bit more
        modular.</p>
      <img :src="require('@/assets/ClassThatInherits.png')" alt="can't load image">
      <p class="Description">This code snippet above is an example of a class where the previous class is inherited.
        This is one of a few different classes that inherits the previously shown functionality and variables.</p>
      <img :src="require('@/assets/ConnectionAndMessageRead.png')" alt="can't load image">
      <p class="Description">Besides the basic game code, I also created a connection to an arduino for this
        application. The code snippet above is needed to connect and receive and read incoming serial messages from the
        arduino.</p>
      <img :src="require('@/assets/ArduinoConnection.png')" alt="can't load image">
      <p class="Description">This code snippet above is an example of a class where the previous class is inherited.
        This is done for modularity.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "1943WinForms"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">

</style>