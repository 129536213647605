<template>
  <nav>
    <ul>
      <li>
        <router-link to="/" exact>Home</router-link>
      </li>
      <li>
        <router-link to="/GameProjects" exact>Game Projects</router-link>
      </li>
      <li>
        <router-link to="/OtherProjects" exact>Other Projects</router-link>
      </li>
      <li>
        <router-link to="/AboutMe" exact>About Me</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PCnavigation"
}
</script>

<style scoped>
nav {
  border-radius: .7vw;
  display: flex;
  width: 100%;
  background: #C5C6C7;
  padding: 14px 0;
  margin-bottom: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

ul {
  text-align: center;
  display: flex;
  list-style: inside;
  padding: 0;
  margin: 0 20px 0 0;
}

li {
  display: inline-block;
  padding: 2px 10px;
  cursor: pointer;
  font-size: calc((.4em + 1vmin) + (.4em + 1vmax));
}

a {
  text-align: center;
  color: #0B0C10;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: .6vw;
}

.router-link-active {
  background: #0B0C10;
  color: #45A29E;
}
</style>