<template>
  <div>
    <img id='Master' :src="require('@/assets/CargoTruckJump.png')" alt="can't load image">
    <H1>CargoRunner</H1>
    <p class="Description">This Unity project is a personal project I'm currently still working on to learn about using
      wheel colliders and get more experience with level design</p>
    <p class="Description">This Unity project at it's core is a cargo transporting game.
      This game has a hillclimb element and the option to choose different types of transport vehicles,
      which each have their own performance and cargo security</p>
    <p class="Description"> Currently there isn't a trailer video about the game as I haven't created enough content for
      it. I do have a gif of the start screen, code snippets and 3D models for the game.</p>


    <div class="gif">
      <H2>Main menu</H2>
      <p class="Description">The main menu of my game has an active background of a truck jumping with it's cargo.</p>
      <p class="Description">This to give an example of what to expect when playing the game.</p>
      <img :src="require('@/assets/gif_animation_CargoRunner.gif')" alt="can't load gif">
    </div>
    <div class="gif">
      <H2>WIP truck code</H2>
      <img :src="require('@/assets/SpeedController.png')" alt="can't load image">
      <p class="Description">The code shown above is required for truck movement. The functionality displayed above is
        used for the movement of the car. If the player presses the W key on the keyboard it will apply motortorque to
        the wheelcolliders on the vehicle and move it forward.
        BrakeTorque applies the braking force to stop the vehicle and is applied when the S key is pressed. The idle
        method is used when the W and S keys aren't pressed and applies a small amount of braketorque to simulate engine
        breaking.</p>
      <img :src="require('@/assets/WheelMeshRotationController.png')" alt="can't load image">
      <p class="Description">The code shown above is required for aligning wheel collider rotation with wheel
        meshes.</p>
    </div>

    <H2> 3D models</H2>
    <p class="Description">During this project I've been busy with the models required for the game. Below I added
      images of the models I've made so far.</p>
    <div class="Models">
      <img :src="require('@/assets/Truck.png')" alt="can't load image">
      <img :src="require('@/assets/Cargo.png')" alt="can't load image">
      <img :src="require('@/assets/Bridges.png')" alt="can't load image">
      <img :src="require('@/assets/FullSizeBlocks.png')" alt="can't load image">
      <img :src="require('@/assets/DirtRoadBlocks.png')" alt="can't load image">
      <img :src="require('@/assets/TarmacRoadBlocks.png')" alt="can't load image">
      <img :src="require('@/assets/SpeedBumps.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "CargoRunner"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>