import { render, staticRenderFns } from "./GamesMade.vue?vue&type=template&id=162c4529&scoped=true&"
import script from "./GamesMade.vue?vue&type=script&lang=js&"
export * from "./GamesMade.vue?vue&type=script&lang=js&"
import style0 from "./GamesMade.vue?vue&type=style&index=0&id=162c4529&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162c4529",
  null
  
)

export default component.exports