<template>
  <div class="page">
    <H1>My CV</H1>
    <p id="Information">Loading may take a few seconds.</p>
      <iframe
              src="https://docs.google.com/viewer?srcid=1WSN22debwe6HDoclizCUIzSAUpp2AYLg&pid=explorer&efh=false&a=v&chrome=false&embedded=true#view=fitw"
              width="580px" height="480px"></iframe>
  </div>
</template>

<script>

export default {
  name: "PdfViewer"
};
</script>

<style>
iframe {
  position: relative;
  width: 100%;
  height: 100vh;
}
H1 {
  font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
  color: #66FCF1;
}

p {
  font-family: Arial, sans-serif;
  align-self: center;
  color: whitesmoke;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}

.page {
  min-height: 85vh;
  height: max-content;
  margin-bottom: 30px;
}
</style>