<template>
  <div>
    <img id="Master" :src="require('@/assets/DGSLogo.jpg')" alt="can't load image">
    <H1>Internship Assignment Dutch Game Studio</H1>
    <p class="Description">During this internship I assisted in further development of their in house Typescript engine, the BrandedGames Engine.</p>
    <p class="Description">In the half year I worked for the company during my Internship I was originally assigned to adding Augmented-Reality functionalities to their current
    version of the game engine. Over time my role in the company changed to add 3D rendering to the engine, mainly to be able to create more possibilities for the AR implementation they requested.</p>
    <div class="Models">
    <H2>DinoJump</H2>
    <p class="Description">The first assignment I got during the internship was assist in repairing broken code in their Dinojump clone. This was a project which i worked on for about 3 weeks, with 3 fellow interns to assist me. Below I've added an image of the game.</p>
      <img :src="require('@/assets/DinoJump.png')" alt="can't load image">
      <p class="Description">As this is a game, owned by Dutch Game Studio's, I won't share more images of my game, but I can describe what I had to do for this game, which I will shortly describe below.</p>
      <p class="Description">When I started at DGS, I was challenged with working with unfamiliar code, written by the company and interns before me. The engine code was filled with a lot of bugs, mainly related to physics and movement.</p>
      <p class="Description">Within the 3 weeks I worked on the code, I fixed spawning of objects together with object pooling, worked on character movement, in this case, jumping, UI loading and fixed collision issues in the game.</p>
      <p class="Description">Object pooling and collision were challenges for which I had to ask for help from one of the other interns in order to get it working properly, as there was a lot of code connected to it which had to be checked.</p>
      <p class="Description">The image I added above shows the final result of the game in the 3 weeks I was bugfixing the game and solving issues in the engine. The game itself was by no means finished when I was assigned a new task.</p>
      <br/>
    </div>
    <div class="Models">
      <H2>Creating a modular BrandedGames engine</H2>
      <p class="Description">The most boring assignment of my internship, where I spend most of my time on, was updating the structure of the BrandedGames engine and separating it's functionalities into a modular structure.</p>
      <p class="Description">Below I've added a list of elements, to show what components have been set up for the engine to create the modular approach. This list will be all components of the engine at the end of my internship.</p>
      <div>
      <ul>
        <Li>Core: The main part of the game engine that makes the engine work</Li>
        <li>Pixi: Open source 2D (Sprite) rendering library implementation of the engine</li>
        <li>Box2D: Open source 2D physics library implementation of the engine</li>
        <li>Babylon: Open source 3D rendering library implementation of the engine</li>
        <Li>2D Render Interface: Interface layer connecting the Pixi2D rendering functionality to the engine</Li>
        <li>3D Render Interface: Interface layer connecting the Babylon rendering functionality to the engine</li>
        <li>Physics interface: Interface layer connecting the Box2D physics functionality to the engine</li>
        <li> AFrame Augmented Reality: Open source AR library, capable of 2D and 3D display. First testing stage of AR in the engine</li>
        <li>Math: Contains all math related code required to run physics within the engine.</li>
      </ul>
      </div>
      <br/>
      <p class="Description">For the conversion of the engine I had 1, sometimes 2 other interns assisting me, depending on if the 2nd intern had other assignments or not.</p>
      <p class="Description">Of all the components above, I was mostly working on rewriting/ splitting the logic of the BrandedGames engine as a whole into: Core, Pixi and 2D Render interface, whilst the other interns took on the Box2D and Physics interface logic.</p>
      <p class="Description">After a long time of rewriting and debugging the rewritten and splitted logic, I started development on the implementation of Babylon 3D rendering and the 3D Render interface, enabling the engine to render 3D objects.
        I will write and show the results of this challenge after this.
        At the end of my internship I worked on a first implementation of Aframe Augmented Reality for about a week, before my internship came to an end.</p>
      <p class="Description">Excluding the Core and math components of the BrandedGames engine, this setup made it possible for the company to create specific profiles for the engine,
        so they can add the required components to the engine depending on the type of game they want to create.</p>
      <p class="Description">The creating of specific profiles I was unable to do as time ran out, but at the end of my internship, everything was ready for use.</p>
    </div>
      <br/>
    <div class="Models">
      <H2>3D rendering</H2>
      <p class="Description">As previously mentioned, 3D rendering works using the open source Babylon 3D rendering library.
        I was challenged by DGS to, after researching the engine and a few alternatives, create a 'wrapper' that would take the rendering functionalities of Babylon
        and let them work together with the BrandedGames engine and 2D rendering.</p>
      <p class="Description">It was quite frustrating and I had a lot of setbacks getting the library to run with the engine, but after about 6 or 7 weeks of trial and error, I managed to create a first version of the 3D rendering implementation.</p>
      <p class="Description">This assignment was much more challenging as I had to research the library thoroughly and focus on calling and implementing the right 3D rendering logic.
        For this I also had to get data in a structured way, this so a different 3D rendering library could be used if the company wants to, without having to rewrite to much code.
        This is where the 3D Rendering Interface came in. This made me able to keep the implementation modular and with gathered information of some possible other libraries,
        create an easilly replacable solution that required little to no rewriting to function.</p>
      <br/>
      <p class="Description">At the end of the project I was able to put all the functionalities of the engine together and have 2D and 3D running side by side. To show 3D rendering in action, I added 2 images below to show the functionality in working form.
        1 without and 1 with 2D and 3D rendering together.</p>
      <img :src="require('@/assets/Babylon3DRenderingObjects.png')" alt="can't load image">
      <img :src="require('@/assets/babylon3DRenderingWithPixi2DSprites.png')" alt="can't load image">
      <p class="Description">The biggest challenge in the end was that I had to rewrite all the math calculations in the engine to perform both 2D and 3D calculation in the right manner.
      This took most of the time I was working on 3D to do right</p>
    </div>
    <br/>
    <div class="Models">
      <H2>Augmented Reality</H2>
      <p class="Description">AR was one of the more easy libraries to implement as rendering was already done by the engine itself.
        The things I had to work on during the last few weeks of my internship were to get the rendering output of the engine for the library and then to project this on a marker.</p>
      <p class="Description">Getting an image of the library working is quite the challenge as it is a very early implementation. Because of this the image is of lesser quality than other images.</p>
      <img :src="require('@/assets/WebAR.png')" alt="can't load image">
    </div>
    <br/>
    <div class="Models">
      <H2>Models used for 3D rendering</H2>
      <img :src="require('@/assets/XWing.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "Dutch Game Studio"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>