<template>
  <div>
    <img id="Top" :src="require('@/assets/Recontain.png')" alt="can't load image">
    <H1>Project Recontain</H1>
    <p class="Description">This project was an international hackathon hosted by different colleges from around the
      world, based on teamwork with people of different nationalities to find a solution that reduces the amount of
      plastic waste. </p>
    <p class="Description">The challenge me and my group had to work on was: find a new purpose for recycled pc pallets.
      The solution me and my group came with didn't require much programming.</p>
    <p class="Description">link to the hack the waste website can be found here:</p>
    <a href="https://hackthewaste.com/2020/" target="_blank">Hack the wastes home</a>
    <p class="Description"> More details about results of the project can be found here:</p>
    <a href="https://hackthewaste.com/wordpress/2020/05/18/the-dirt-bags/" target="_blank">Hack the wastes group</a>
    <p class="Description">
      The idea we as team came up with was using the pc pallets to create panels for cargo containers.
    </p>
    <p class="Description">
      These panels would reduce the weight of currently used containers and besides finding a use for left over pallets,
      also reduce over all strain on the environment by reducing the fuel cost of transporting the cargo container.
    </p>
    <p class="Description">
      During this project I worked on 3D models for the concept we were working on. Furthermore I did research to
      machinery that would be required to produce the plastic panels we would need for the containers we made up.
    </p>
    <p class="Description">
      Most of the work we did together as a team so there isn't much to really cover specifically. Below I have added a
      render of the models I've made for this project.
    </p>
    <H2> 3D models</H2>
    <div class="Models">
      <img :src="require('@/assets/FilledContainers.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "Project Recontain"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>