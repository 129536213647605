<template>
  <div>
    <img id="Top" :src="require('@/assets/SpaceInvaders.png')" alt="can't load image">
    <H1>SpaceInvaders</H1>
    <p class="Description">The first project I created as a programmer.
      This is a simple form of the game Space Invaders, made with C# windows forms.</p>
    <p class="Description">The idea of this project was to get familure with programming and new options to learn about.
      With this project I learned how to work with classes.</p>
    <br/>
    <H2>A recording of the project in action</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/_pLS0m8I3VQ" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
    <H2> Code snippets</H2>
    <div class="CodeSnippets">
      <H3>Invader</H3>
      <img :src="require('@/assets/InvaderCode.png')" alt="can't load image">
      <p class="Description">The code snippet shown above is the core logic for the enemies in the game.
        The enemy moves with a given speed every x amount of time and when hit takes damage.</p>
      <p class="Description"> If the enemy healt is not equal to 0, it goes to the start position at the top of the
        screen and tries to get to the bottom again.
        If an enemy reaches the bottom of the screen the enemy will be killed and the player takes damage.</p>
      <H3>Spawner</H3>
      <img :src="require('@/assets/SpawnerCode.png')" alt="can't load image">
      <p class="Description">The code snippet shown above is for the spawning of enemies.
        This code works on a timer and randomises which enemy spawns every time it gets run.
        Each enemy has it's own chance to spawn, as shown in the if statements above each code block with the 'spawn'
        values.</p>
      <H3>Movement</H3>
      <img :src="require('@/assets/MovementCode.png')" alt="can't load image">
      <p class="Description">The code snippet shown above is for player movement and attacks. If the player pressed A or
        D, the ship goes one step left or right on the screen, or moves to a side as long as the button is held down.
        If the player hits a border of the screen, the ship will be teleported to the other side and continue from
        there.</p>
      <p class="Description">Besides movement does this snippet also check for the back and spacebar buttons. This so
        the player can shoot if there are bullets in his magazine and pause/ continue the game at will.</p>
      <p class="Description">The logic shown will, logically, only work if a player has a ship with more than 0 hp
        left.</p>
      <H3>hit detection</H3>
      <img :src="require('@/assets/HitDetection.png')" alt="can't load image">
      <p class="Description">The code snippet shown above is used to check if bullets hit an enemy.
        If so, the enemy gets damaged and the bullet destroyed. </p>
      <p class="Description">If the player hits an enemy the bullet will be added to it's magazine. If no enemy is hit,
        the bullet is lost.
        An another additional feature which I didn't have time for to implement is death by meteorites. If a player gets
        hit by a meteorite he gets killed instantly.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpaceInvadersWinForms"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>