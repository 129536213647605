<template>
  <div class="page">
    <H1 id="Title">Other Projects</H1>
    <p id="Information">These projects are miscellaneous non-unity related projects I worked on during my time as
      programmer.</p>
    <div class="Wrapper-grid">
      <div class="container"
           @click=Recontain>
        <img :src="require('@/assets/Recontain.png')" alt="can't load image">
        <H1 class="ProjectName">Project ReContain</H1>
        <H3 class="Status">Hackathon project</H3>
      </div>
      <div class="container"
           @click=WinForms>
        <img :src="require('@/assets/1943WinForms.png')" alt="can't load image">
        <H1 class="ProjectName">1943 Windows forms clone</H1>
        <H3 class="Status">Study project</H3>
      </div>
      <div class="container"
           @click=SpaceInvaders>
        <img :src="require('@/assets/SpaceInvaders.png')" alt="can't load image">
        <H1 class="ProjectName">Space Invaders</H1>
        <H3 class="Status">Study project</H3>
      </div>
      <div class="container"
           @click=DGS>
        <img :src="require('@/assets/DGSLogo.jpg')" alt="can't load image">
        <H1 class="ProjectName">Brandedgames-Engine Dutch Game Studio's</H1>
        <H3 class="Status">Internship project</H3>
        <p class="ShortDescription">Further developing a Typescript Game Engine</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects completed",
  methods: {
    Recontain() {
      this.$router.push("/Recontain")
    },
    WinForms() {
      this.$router.push("/WinForms")
    },
    SpaceInvaders() {
      this.$router.push("/Invaders")
    },
    DGS(){
      this.$router.push("/DGS")
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  margin: 0;
  padding-top: 3px;
}

.Wrapper-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, .7fr));
  gap: 30px;
  grid-gap: 20px;
  justify-content: center;
}

.container {
  padding-top: 10px;
  box-shadow: 0 2px 8px 0 #66FCF1;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  background-color: #66FCF1;
  padding-bottom: 10px;
}

.container:hover {
  background-color: #45A29E;
}

img {
  position: center;
  height: auto;
  width: 70%;
  background-position: center;
  border-radius: 1rem;
}

.ProjectName {
  color: #0B0C10;
  font-size: calc((.4em + .7vmin) + (.4em + .7vmax));
  margin-top: 10px;
}

.Status {
  color: #0B0C10;
  font-size: calc((.4em + .6vmin) + (.4em + .6vmax));
  margin-top: 10px;
}

#Information {
  margin-bottom: 20px;
  color: whitesmoke;
}

#Title {
  margin-bottom: 10px;
  color: #66FCF1;
}

.page {
  min-height: 85vh;
  height: max-content;
  margin-bottom: 30px;
}

H1 {
  font-family: Arial, sans-serif;
  color: #66FCF1;
  font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
}

p {
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}
.ShortDescription {
  margin: 1rem 2rem;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
  color: #0b0c10;
}
</style>