<template>
  <div>
    <img id="Master" :src="require('@/assets/FireandQuestions.png')" alt="can't load image">
    <H1>FireAndQuestions</H1>
    <p class="Description">This was a six person Unity project for the insurance company Achmea.</p>
    <p class="Description">During the final 8 weeks of the semester, me and my fellow teammates were tasked to create a
      game for Achmea as an Industry Project.
      An industry project is basically a challenge/assignment given by a company which we have to work on and create a
      solution for.
      The challenge was to create a game that teaches both employee's and the employer about how to prevent fire.
      Our goal with this game was to spread awareness about fire hazards and how to solve, prevent and extinguish fires
      safely.
      This game is focussed on showing different types of hazards and learning the player about different kinds oh fire
      hazards during gameplay.</p>
    <H2>A recording of the project in action</H2>
    <div class="video-box">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/TQQnRYfmadw" frameborder="0"
              allow="accelerometer; gyroscope;" allowfullscreen></iframe>
    </div>
    <H2>Tasks during the project</H2>
    <p class="Description"> My tasks during this project were: Below I will show some of the models I made and the code
      for the particle system</p>
    <ul>
      <Li>Creating the intro scene</Li>
      <li>Writing a short story for the game (shown in the intro scene)</li>
      <li>Creating a bunch of the used 3D models</li>
      <li>creating and implementing smoke particles with required logic</li>
    </ul>
    <p class="Description"> Below I will show some of the models I made and the code for the particle system.</p>
    <div class="Particles">
      <H2>unity scenes and Code snippets</H2>
      <H3>Intro scene with story and code</H3>
      <p class="Description">Intro with story</p>
      <div>
        <img :src="require('@/assets/gif_animation_FireAndQuestionsIntroGameIntroduction.gif')" alt="can't load gif">
      </div>
      <img :src="require('@/assets/IntroductionCode.png')" alt="can't load image">
      <p class="Description">Code required to create the fading effect shown above. The fade works with a coroutine and
        mathf.lerp to make an automatic and smooth transition scene.</p>
    </div>
    <div class="Particles">
      <H3>Smoke particles and code</H3>
      <p class="Description">Smoke particles</p>
      <div>
        <img :src="require('@/assets/gif_animation_Smoke.gif')" alt="can't load gif">
      </div>
      <img :src="require('@/assets/SmokeParticles.png')" alt="can't load image">
      <p class="Description">The Code above is required for automatically starting smoke particles after x amount of
        time. This so it can be treated as a timed event in the game.</p>
    </div>

    <H2> 3D models</H2>
    <p class="Description">During this project I've been busy with the models required for the game. Below I added
      images of the models I've made during this project</p>
    <div class="Models">
      <img :src="require('@/assets/Table.png')" alt="can't load image">
      <img :src="require('@/assets/KitchenGrill.png')" alt="can't load image">
      <img :src="require('@/assets/ClipBoard.png')" alt="can't load image">
      <img :src="require('@/assets/GrillingPan.png')" alt="can't load image">
      <img :src="require('@/assets/DishWasher.png')" alt="can't load image">
      <img :src="require('@/assets/Fryer.png')" alt="can't load image">
      <img :src="require('@/assets/Microwave.png')" alt="can't load image">
    </div>
  </div>
</template>

<script>
export default {
  name: "FireAndQuestions"
}
</script>

<style scoped src="@/components/SharedCSS/SharedCSS.css">
</style>