<template>
  <footer>
    <p>This portfolio is made by Leslie Crielaard</p>
    <p>Thanks for visiting!</p>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
p {
  font-family: Arial, sans-serif;
  align-self: center;
  color: #0B0C10;
  font-size: calc((.4em + .4vmin) + (.4em + .4vmax));
}

footer {
  padding: 20px;
  background-color: #C5C6C7;
  width: calc(100% - 40px);
}
</style>