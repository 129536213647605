<template>
  <div id="app">
    <phone-navigation id="MobileNav"/>
    <div class="content" :class="{'open':showNav}">
      <div class="NavBar">
        <div id="navIcon" v-if="mobileView"
             @click="showNav =!showNav">
          <font-awesome-icon id="icon" icon="bars"/>
        </div>
        <PCnavigation v-if="!mobileView"/>
      </div>
      <router-view></router-view>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>

import PCnavigation from "@/components/Navigation/PCnavigation";
import PhoneNavigation from "@/components/Navigation/PhoneNavigation";
import Footer from "@/components/Footer";

export default {
  components: {PhoneNavigation, PCnavigation, Footer},
  data: () => {
    return {
      mobileView: false,
      showNav: false
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.mobileView = window.innerWidth <= 990;
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      if (e.type === "resize") {
        this.mobileView = window.innerWidth <= 890;
        if (!this.mobileView) {
          this.showNav = false;
        }
      }
    }
  }
}

</script>

<style>

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #0B0C10;

}

.NavBar {
  display: flex;
  width: 100%;
}

.content {
  position: absolute;
  top: 5px;
  width: calc(100% - 55px);
  padding: 20px;
  margin: auto;
  background-color: #1F2833;
  transition: 1s transform cubic-bezier(0, .12, .14, 1);
  border-radius: 30px;
  height: max-content;
}

.open {
  transform: translateX(300px);
}

#MobileNav {
  left: 30px;
  border-radius: 30px;
}

#navIcon {
  padding: 10px 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

#icon {
  font-size: 2rem;
  position: relative;
}

Footer {
  bottom: 0;
  border-radius: 30px;
  text-align: center;
}
</style>
