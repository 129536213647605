<template>
  <div>

    <Header>
      <div class="flex-container">
        <div class="SliderContainer"
             v-for="number in [currentNumber]"
             :key='number'
        >
          <img id="Master"
               :src="currentImage"
               v-on:mouseover="stopRotation"
               v-on:mouseout="startRotation"
               alt="Can't load slideshow"/>
        </div>
      </div>
      <H1> Welcome to my portfolio!</H1>
      <p> My name is Leslie Crielaard. I'm student studying at Fontys University of Applied Sciences, currently studying
        for a Bachelors degree ICT at Fontys University of applied Sciences in Tilburg.
        Besides the standard Software Engineering the study teaches me, I want to specialize myself in the world of
        GameDesign.
        I have always loved games and with this specialisation I can now bring my ideas to life myself!</p>
    </Header>
    <div id="Achievements">
      <H1>Biggest accomplishments</H1>
      <p>Below I have placed some of my biggest accomplishments during my gamedesign carreer. For more info, click on
        the cards below. More projects can be found under the <a v-on:click="GameTab">Game Projects</a> tab.</p>
      <p>Interested in looking at non gamedesign projects as well? Under <a v-on:click="OtherTab">Other Projects</a> I
        placed some of those.</p>
      <div class="Wrapper-grid">
        <div class="container"
             @click=Cargo>
          <img :src="require('@/assets/CargoTruckJump.png')" alt="can't load image">
          <H1 class="ProjectName">CargoRunner</H1>
          <H3 class="Status">W I P personal project</H3>
          <p class="Description">A project about cargo transportation</p>
          <p class="Description">Programming environment: Visual Studio</p>
          <p class="Description">Game engine: Unity</p>
        </div>
        <div class="container"
             @click=Builder>
          <img :src="require('@/assets/CityBuildIdleMainMenu.png')" alt="can't load image">
          <H1 class="ProjectName">CityBuild Idle</H1>
          <H3 class="Status">Duo game project</H3>
          <p class="Description">A project about building and managing a city</p>
          <p class="Description">Programming environment: Visual Studio</p>
          <p class="Description">Game engine: Unity</p>
        </div>
        <div class="container"
             @click=fireAndQuestions>
          <img id="Card" :src="require('@/assets/FireandQuestions.png')" alt="can't load image">
          <H1 class="ProjectName">FireandQuestions</H1>
          <H3 class="Status">Group project for Achmea</H3>
          <p class="Description">A project about fire prevention</p>
          <p class="Description">Programming environment: Visual Studio</p>
          <p class="Description">Game engine: Unity</p>
        </div>
      </div>
    </div>
    <div>
      <H1>Who Am I?</H1>
      <div class="MeContainer">
        <img :src="require('@/assets/CroppedMe.jpg')" alt="can't load image">
      </div>
      <p> I'm Leslie Crielaard. I'm a programmer, rookie storywriter and beginning 3D modeller studying at Fontys
        University of applied Sciences in Tilburg.</p>
      <p>To learn a bit more about the programming languages I have experience with or read my CV, please take a look at
        the <a v-on:click="Me">About Me</a> page.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data: () => {
    return {
      images: [require('@/assets/CargoTruckJump.png'), require('@/assets/CityBuildIdleMainMenu.png'), require('@/assets/Rothinger case.png'), require('@/assets/FireandQuestions.png')],
      currentNumber: 0,
      number: 5,
      timer: null
    }
  },
  mounted: function () {
    this.startRotation();
  },
  methods: {
    fireAndQuestions() {
      this.$router.push("/FireAndQuestions")
    },
    Cargo() {
      this.$router.push("/CargoRunner")
    },
    Builder() {
      this.$router.push("/CityBuild")
    },
    GameTab() {
      this.$router.push("/GameProjects")
    },
    OtherTab() {
      this.$router.push("/OtherProjects")
    },
    Me() {
      this.$router.push("/AboutMe")
    },
    startRotation: function () {
      this.timer = setInterval(this.next, 6000);
    },
    stopRotation: function () {
      clearTimeout(this.timer);
      this.timer = null;
    },
    next: function () {
      this.currentNumber += 1
    },
    prev: function () {
      this.currentNumber -= 1
    },
  },
  computed: {
    currentImage: function () {
      return this.images[Math.abs(this.currentNumber) % this.images.length];
    }
  }
}

</script>

<style scoped>
Header {
  font-family: Arial, sans-serif;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-bottom: 20px;
}

p {
  font-family: Arial, sans-serif;
  align-self: center;
  color: whitesmoke;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}

H1 {
  font-family: Arial, sans-serif;
  color: #66FCF1;
  font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
}

.Wrapper-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, .7fr));
  gap: 30px;
  grid-gap: 20px;
  justify-content: center;
  align-content: center;
}

.container {
  justify-content: center;
  padding-top:10px;
  box-shadow: 0 2px 8px 0 #66FCF1;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  background-color: #66FCF1;
}

.container:hover {
  background-color: #45A29E;
}

.Status {
  font-size: calc((.4em + 1vmin) + (.4em + 1vmax));
}

.ProjectName {
  font-family: Arial, sans-serif;
  font-size: calc((.4em + 1vmin) + (.4em + 1vmax));
}

.Description {
  font-family: Arial, sans-serif;
  margin: 1rem 2rem;
  color: #0B0C10;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}

img {
  pointer-events: none;
  position: center;
  height: auto;
  width: 95%;
  background-position: center;
  border-radius: 1rem;
}

#Achievements {
  font-family: Arial, sans-serif;
  margin-top: 60px;
}

.ProjectName {
  color: #0B0C10;
}

.MeContainer {
  width: 100%;
  height: auto;
  padding-top: 0;
  margin: 0 10px 10px 10px;
  text-align: center;
  position: center;
}

.MeContainer img {
  pointer-events: none;
  height: auto;
  min-width: 100px;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  border-radius: 50%;
  display: block;
}

.SliderContainer {
  width: auto;
  height: 42vw;
  overflow: hidden;
  min-height: 240px;
}

.SliderContainer img {
  pointer-events: none;
  height: 100%;
}

a {
  color: #66FCF1;
}
</style>