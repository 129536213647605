<template>
  <div class="page">
    <H1 id="Title">Game Projects</H1>
    <p id="Information">These projects are games I've worked on/ am currently working on. These are both for school and
      own studies.</p>
    <div class="Wrapper-grid">
      <div class="container"
           @click=Cargo>
        <img :src="require('@/assets/CargoTruckJump.png')" alt="can't load image">
        <H1 class="ProjectName">CargoRunner</H1>
        <H3 class="Status">W I P personal project</H3>
        <p class="ShortDescription">A project about cargo transportation</p>
        <p class="ShortDescription">Programming environment: Visual Studio</p>
        <p class="ShortDescription">Game engine: Unity</p>
      </div>
      <div class="container"
           @click=Builder>
        <img :src="require('@/assets/CityBuildIdleMainMenu.png')" alt="can't load image">
        <H1 class="ProjectName">CityBuild Idle</H1>
        <H3 class="Status">Duo game project</H3>
        <p class="ShortDescription">A project about building and managing a city</p>
        <p class="ShortDescription">Programming environment: Visual Studio</p>
        <p class="ShortDescription">Game engine: Unity</p>
      </div>
      <div class="container"
           @click=fireAndQuestions>
        <img id="Card" :src="require('@/assets/FireandQuestions.png')" alt="can't load image">
        <H1 class="ProjectName">FireandQuestions</H1>
        <H3 class="Status">Group project for Achmea</H3>
        <p class="ShortDescription">A project about fire prevention</p>
        <p class="ShortDescription">Programming environment: Visual Studio</p>
        <p class="ShortDescription">Game engine: Unity</p>
      </div>

      <div class="container"
           @click=rothingerCase>
        <img :src="require('@/assets/Rothinger case.png')" alt="can't load image">
        <H1 class="ProjectName">Rothinger Case</H1>
        <H3 class="Status">Group project duo game</H3>
        <p class="ShortDescription">Project type: Horror game</p>
        <p class="ShortDescription">Programming environment: Visual Studio</p>
        <p class="ShortDescription">Game engine: Unity</p>
      </div>

      <div class="container"
           @click=SpaceOtic>
        <img :src="require('@/assets/Space-otic_logo.png')" alt="can't load image">
        <H1 class="ProjectName">SpaceOtic</H1>
        <H3 class="Status">Group project GameJam</H3>
        <p class="ShortDescription">GameJam theme: Let there be chaos</p>
        <p class="ShortDescription">Programming environment: Visual Studio</p>
        <p class="ShortDescription">Game engine: Unity</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Games made",
  methods: {
    rothingerCase() {
      this.$router.push("/RothingerCase")
    },
    fireAndQuestions() {
      this.$router.push("/FireAndQuestions")
    },
    Cargo() {
      this.$router.push("/CargoRunner")
    },
    Builder() {
      this.$router.push("/CityBuild")
    },
    SpaceOtic(){
      this.$router.push(("/SpaceoticGameJam"))
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  margin: 0;
  padding-top: 3px;
}

.Wrapper-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, .7fr));
  gap: 30px;
  grid-gap: 20px;
  justify-content: center;
}

.container {
  padding-top: 10px;
  box-shadow: 0 2px 8px 0 #66FCF1;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  background-color: #66FCF1;
}

.container:hover {
  background-color: #45A29E;
}

img {
  position: center;
  height: auto;
  width: 95%;
  background-position: center;
  border-radius: 1rem;
}

.ProjectName {
  color: #0B0C10;
  font-size: calc((.4em + .7vmin) + (.4em + .7vmax));
  margin-top: 10px;
}

.Status {
  color: #0B0C10;
  font-size: calc((.4em + .6vmin) + (.4em + .6vmax));
  margin-top: 10px;
}

.ShortDescription {
  margin: 1rem 2rem;
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
  color: #0b0c10;
}

#Information {
  margin-bottom: 20px;
  color: whitesmoke;
}

#Title {
  margin-bottom: 10px;
  color: #66FCF1;
}

H3 {
  margin-bottom: 10px;
}

.page {
  min-height: 85vh;
  height: max-content;
  margin-bottom: 30px;
}

H1 {
  font-family: Arial, sans-serif;
  color: #66FCF1;
  font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
}

p {
  font-size: calc((.4em + .5vmin) + (.4em + .5vmax));
}
</style>